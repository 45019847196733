<template>
  <section>
      <div class="content-header">
        <h2>Works cited</h2>
      </div>
      <div class="content-wrapper">
        <p>Here are the resources cited throughout this section of the toolkit, in the order in which they appeared. </p>
        <h3>Blog posts</h3>
        <ul>
          <li class="pb-3">Inside Higher Ed. (2020, March 17). <em>10 strategies to support students and help them learn during the</em>. <a href="https://www.insidehighered.com/advice/2020/03/17/10-strategies-support-students-and-help-them-learn-during-coronavirus-crisis" target="_blank">www.insidehighered.com/advice/2020/03/17/10-strategies-support-students-and-help-them-learn-during-coronavirus-crisis</a></li>
          <li class="pb-3">Nawaz, F. (2019, November 30). 7 Secrets to help you build academic resilience. <em>Editage Insights</em>. <a href="https://www.editage.com/insights/7-secrets-to-help-you-build-academic-resilience" target="_blank">www.editage.com/insights/7-secrets-to-help-you-build-academic-resilience</a></li>
          <li class="pb-3">InnerDrive. (2020, February 19). <em>How to Improve Academic Buoyancy</em>. InnerDrive UK. <a href="https://blog.innerdrive.co.uk/how-to-improve-academic-buoyancy" target="_blank">blog.innerdrive.co.uk/how-to-improve-academic-buoyancy</a></li>
          <li>Psychreg. (2021, March 7). <em>How Can We Build Academic Resilience?</em> <a href="https://www.psychreg.org/academic-resilience/" target="_blank">www.psychreg.org/academic-resilience/ </a></li>
        </ul>
        <h3>Journal articles</h3>
        <ul>
          <li class="pb-3">Ye, W., Strietholt, R., & Blömeke, S. (2021). Academic resilience: underlying norms and validity of definitions. <em>Educational Assessment, Evaluation and Accountability, 33</em>(1), 169-202. <a href="https://link.springer.com/article/10.1007/s11092-020-09351-7" target="_blank">link.springer.com/article/10.1007/s11092-020-09351-7</a></li>
          <li class="pb-3">Jowkar, B., Kojuri, J., Kohoulat, N., & Hayat, A. A. (2014). Academic resilience in education: the role of achievement goal orientations. <em>Journal of advances in medical education & professionalism, 2</em>(1), 33–38.</li>
          <li class="pb-3">Martin, Andrew & Marsh, Herb. (2008). Academic buoyancy: Towards an understanding of students' everyday academic resilience. Journal of School Psychology. 46. 53-83. 10.1016/j.jsp.2007.01.002.</li>
          <li class="pb-3"><em>Learning analytics messages: Impact of grade, sender, comparative information and message style on student affect and academic resilience</em>. (2018, December 1). ScienceDirect. <a href="https://www.sciencedirect.com/science/article/abs/pii/S0747563218303431" target="_blank">www.sciencedirect.com/science/article/abs/pii/S0747563218303431</a><a href="" target="_blank"></a></li>
          <li class="pb-3">Perez, W. (2009). Academic Resilience Among Undocumented Latino Students. <em>Hispanic Journal of Behavioral Sciences</em>. Published. <a href="https://doi.org/10.1177/0739986309333020" target="_blank">doi.org/10.1177/0739986309333020</a><a href="" target="_blank"></a></li>
          <li class="pb-3">Ashrafi, S., Moradi, R., Mashhadi, A., & Bashir Nejad, H. (2021). Effectiveness of Time Management Training on Nursing Students’ Academic Achievement and Resilience. <em>Future of Medical Education Journal, 11</em>(1). <a href="https://doi.org/10.22038/FMEJ.2020.47527.1323" target="_blank">doi.org/10.22038/FMEJ.2020.47527.1323</a><a href="" target="_blank"></a></li>
          <li class="pb-3">Kumi-Yebaoh, Alex. (2020). Educational Resilience and Academic Achievement of Immigrant Students From Ghana in an Urban School Environment. 753-782.<a href="" target="_blank"></a></li>
          <li class="pb-3">Cassidy S. (2015). Resilience Building in Students: The Role of Academic Self-Efficacy. <em>Frontiers in psychology, 6</em>, 1781. <a href="https://doi.org/10.3389/fpsyg.2015.01781" target="_blank">doi.org/10.3389/fpsyg.2015.01781</a><a href="" target="_blank"></a></li>
          <li class="pb-3">Vollet, Justin & Kindermann, Thomas. (2020). Promoting Persistence: Peer Group Influences on Students' Re-engagement Following Academic Problems and Setbacks. International Journal of Behavioral Development. 44. 354-364. 10.1177/0165025419880614.<a href="" target="_blank"></a></li>
          <li class="pb-3">Nadia Coleman (2015) Promoting resilience through adversity: increasing positive outcomes for expelled students, Educational Studies, 41:1-2, 171-187, DOI: <a href="https://www.tandfonline.com/doi/abs/10.1080/03055698.2014.955741" target="_blank">10.1080/03055698.2014.955741</a></li>
          <li class="pb-3">Thomas LJ, Asselin M. Promoting resilience among nursing students in clinical education. Nurse Educ Pract. 2018 Jan;28:231-234. doi: 10.1016/j.nepr.2017.10.001. Epub 2017 Oct 7. PMID: 29128734.<a href="" target="_blank"></a></li>
          <li class="pb-3">Martin, A. (2002). Motivation and Academic Resilience: Developing a Model for Student Enhancement. Australian Journal of Education, 46(1), 34–49. <a href="https://journals.sagepub.com/doi/10.1177/000494410204600104" target="_blank">doi.org/10.1177/000494410204600104</a></li>
          <li class="pb-3">Skinner, Ellen & Pitzer, Jennifer & Brule, Heather & Rickert, Nicolette & Kindermann, Thomas. (2019). “I get knocked down but I get up again:” Integrative frameworks for studying the development of motivational resilience in school. International Journal of Behavioral Development. 44. 10.1177/0165025420924122.<a href="" target="_blank"></a></li>
          <li class="pb-3">Brandi N. Frisby, Angela M. Hosek & Anna Carrie Beck (2020) The role of classroom relationships as sources of academic resilience and hope, Communication Quarterly, 68:3, 289-305, DOI: 10.1080/01463373.2020.1779099<a href="" target="_blank"></a></li>
          <li class="pb-3">Schacter, D. L. & Szpunar, K. K. (2015). Enhancing attention and memory during video-recorded lectures. Scholarship of Teaching and Learning in Psychology, 1(1), 60-71.<a href="" target="_blank"></a></li>
          <li class="pb-3">M. Alexis Karris Bachik, Gregory Carey & W. Edward Craighead (2021) VIA character strengths among U.S. college students and their associations with happiness, well-being, resiliency, academic success and psychopathology, The Journal of Positive Psychology, 16:4, 512-525, DOI:<a href="https://www.tandfonline.com/doi/full/10.1080/17439760.2020.1752785" target="_blank">10.1080/17439760.2020.1752785</a></li>
          <li class="pb-3">Zimmer-Gembeck MJ. Introduction to the special section: Mindfulness in me and in you—Measurement, development, and implications for adolescents’ emotional and academic resilience. International Journal of Behavioral Development. 2020;44(1):1-4. doi:10.1177/0165025419885029<a href="" target="_blank"></a></li>
          <li class="pb-3">Karabıyık, C. (2020). Interaction between academic resilience and academic achievement of teacher trainees.
International Online Journal of Education and Teaching (IOJET), 7(4). 1585-1601.
<a href="" target="_blank"></a></li>
          <li class="pb-3">DiTullio G. Classroom culture promotes academic resiliency. Phi Delta Kappan. 2014;96(2):37-40. doi:10.1177/0031721714553408<a href="" target="_blank"></a></li>
          <li >Sood S, Sharma A. Resilience and Psychological Well-Being of Higher Education Students During COVID-19: The Mediating Role of Perceived Distress. Journal of Health Management. 2020;22(4):606-617. doi:10.1177/0972063420983111<a href="" target="_blank"></a></li>
        </ul>
        <h3>Magazine articles</h3>
        <ul>
          <li class="pb-3">Krislov, M. (2019, August 27). How We Build Resilience at College And At Home. <em>Forbes</em>. <a href="https://www.forbes.com/sites/marvinkrislov/2019/08/27/how-we-build-resilience-at-college-and-at-home/?sh=17a5e21044b5" target="_blank">www.forbes.com/sites/marvinkrislov/2019/08/27/how-we-build-resilience-at-college-and-at-home/?sh=17a5e21044b5</a><a href="" target="_blank"></a></li>
          <li >Chen, T. (2016, December 13). A Student Cried After Receiving This Touching “Thank You” Email From Her Professor. <em>BuzzFeed</em>. <a href="https://www.buzzfeed.com/tanyachen/thank-you-professor-langer" target="_blank">www.buzzfeed.com/tanyachen/thank-you-professor-langer</a></li>
        </ul>
        <h3>Videos</h3>
        <ul>
          <li class="pb-3"><em>Academic Buoyancy.</em> (2021, July 12). [Video]. YouTube. <a href="https://www.youtube.com/watch?v=otR9HkrFcEw" target="_blank">www.youtube.com/watch?v=otR9HkrFcEw</a></li>
          <li><em>Academic Resilience Series Featuring Lisa House, PH.D</em>. (2020, December 17). [Video]. YouTube. <a href="https://www.youtube.com/watch?v=Kpfwsd9CJ4c" target="_blank">www.youtube.com/watch?v=Kpfwsd9CJ4c</a></li>
        </ul>
        <h3>Book chapters</h3>
        <ul>
          <li>Rankin, P. (2016). Building Resilience in Adult Online Students.</li>
        </ul>
        <h3>Online resources</h3>
        <ul>
          <li>Canada Life. (2020). From Surviving to Thriving: Developing Personal and Academic Resilience. Workplace Strategies for Mental Health. <a href="https://wsmh-cms.mediresource.com/wsmh/assets/ijq4md4roxwkgck0" target="_blank">wsmh-cms.mediresource.com/wsmh/assets/ijq4md4roxwkgck0</a></li>
        </ul>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '02',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
